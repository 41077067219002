let mobileNavBtn = document.querySelector('.header-st_mobile__button');
let mobileNav = document.querySelector('.sprite-blueSidebar');

mobileNavBtn.addEventListener('click', function(){
    mobileNav.classList.toggle('active');
    document.body.classList.toggle('no-scroll')
});

var url,
    isplay,
    init = true,
    loader = 0,
    state = window.history.pushState !== undefined,
    HLS = '?hls=true',
    isDebugger = false,
        pleerContainer = "pleer",
        backwardSong    = ".import_ser-track-central_left-btn",
        importLongPlayerPlayBtn   = ".play",
        importLongPlayerPauseBtn  = ".pause",
        forwardSong        = ".import_ser-track-central_right-btn",
        btnMute   = ".import_ser-mute",
        btnUnmute = ".import_ser-unmute",
        volume = ".import_ser-volume",
        volumeLine = ".import_ser-volume__line",
        importLongTitle = ".import_ser-player-title_heading",
        importLongTime = ".import_ser-zero-time",
        playerProgressBarContainer = ".progress-bar__container",
        playerProgressStatus = ".progress-bar__container-playbar",
        importLongDuration = ".import_ser-timeBox",

        artistContainer = ".music-artist",
        artistBox = ".music-artist__card",
        artistBoxLink = ".link",
        artistDuration = ".dur",
        artistBoxTitle = ".title",
        artistBoxShareBtn = ".share",

	handlerArtist = function (data) {
		arr = data.split("<!|!>");
		let _artistContainer = $(`*${artistContainer}`);

		if (arr[4] !== undefined && arr[4] !== null && arr[4] !== '') window.document.title = arr[4];
        $(".seo-title").text(arr[3]);
		$("h1").html(arr[1]);
        _artistContainer.html(arr[2]);
		additems(_artistContainer);
		$("#string").removeClass("load");
		$('body,html').animate({scrollTop: 0}, 300);
		$("div.search").removeClass("fixedBarStart");
		$("div.top").css("margin-bottom", "0");

		if (isplay) {
            $(`*${artistContainer} > div > a[href='${url}']`).parent().addClass("plays");
		}
		loader = 0;
		newad();
	},
    handlerNextPage = function (data, oplink) {
        arr = data.split("<!|!>");
        let _artistContainer = $(`*${artistContainer}`);
        $("a.nextpage").replaceWith(arr[0]);
        additems(_artistContainer);

        if (isplay) {
            $(`*${artistContainer} > div > a[href='${url}']`).parent().addClass("plays");
        }
        loader = 0;
        newad();
    },
    handlerPrevPage = function (data, oplink) {
        arr = data.split("<!|!>");
        let _artistContainer = $(`*${artistContainer}`);
        $("a.prevpage").replaceWith(arr[2]);
        additems($(_artistContainer));
        if (isplay) {
            $(`*${lartistContainer} > div > a[href='${url}']`).parent().addClass("plays");
        }
        loader = 0;
        newad();
    },
    handlerLyrics = function (data, diving, oplink) {
        arr = data.split("<!|!>");
        diving.find("span.lyrics").html(arr[1]);
        diving.find("a.lyrics").unbind("click").click(function () {
            diving.find("span.lyrics").toggleClass("inv");
            return false;
        });
        newad();
    };

function isHls(url) {
    let hlsParamName = HLS.split('=').shift().substring(1);
    return (new URL(location.protocol + url)).searchParams.get(hlsParamName) === 'true';
}
function newad() {
	let _cbr1 = $("div.cbr1"),
        _right = $("div.right");

    temp = _cbr1.html();
    _cbr1.html(" ");
    _cbr1.html(temp);

    temp = _right.html();
    _right.html(" ");
    _right.html(temp);
}

$.address.state('')
    .init(function () {return;
        $('span.title > a').address();
        $('a#codeint').address();
        $('a.logo').address();
        $('.genre a').address()
            .bind('click', function () {
                if (jQuery(document).width() < 769) {
                    $(".genre-padd").slideToggle();
                }
            });
    })
    .change(function (event) {
        var freeValue = $.address.state().replace(/^\/$/, '') + event.value;
        var value = '/ajax' + freeValue;

        $('.genre a').each(function () {
            if ($(this).attr('href') == freeValue) {
                $(this).addClass('selected');
            } else {
                $(this).removeClass('selected');
            }
        });

        if (state && init) {
            init = false;
        } else {
            $("main-awesome_search__form").css("display", "block");
            hiding = 0;
            $('#string').addClass('load');

            $.ajax({
                url: value,
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    handlerArtist(XMLHttpRequest.responseText);
                },
                success: function (data, textStatus, XMLHttpRequest) {
                    handlerArtist(data);
                }
            });
        }
    });

function additems(obj) {
    obj
        .find(`*${artistBox}`)
        .each(function (index) {

            $(this).find(`*${artistBoxLink}`)
                .click(function () {
                    title = $(this).parent().find(`*${artistBoxTitle}`).text();
                });

            $(this).find(`*${artistBoxShareBtn}`)
                .click(function () {
                    parentObj = $(this).parent();
                    shareTitle = parentObj.find(`*${artistBoxTitle}`).text();
                    sgareDomain = $(this).attr('data-domain');
                    shareTitleEncode = '%E2%99%AB%20' + encodeURIComponent(shareTitle);
                    shareLink = encodeURIComponent('http://' + sgareDomain + '/?mp3=' + encodeURI(shareTitle));
                    appendCode =
                        '<span class="import_ser-shareblock"><b title="закрыть">&times;</b><h1>Поделиться ссылкой на песню<br /><strong>' + shareTitle + '</strong></h1><span>' +
                        '<a class="vk" href="http://vk.com/share.php?url=' + shareLink + '&title=' + shareTitleEncode + '&description=&image=" target="_blank">ВКонтакте</a>' +
                        // '<a class="fb" href="http://www.facebook.com/sharer.php?src=sp&u=' + shareLink + '&t=' + shareTitleEncode + '&description=&picture=" target="_blank">Facebook</a>' +
                        // '<a class="tw" href="https://twitter.com/intent/tweet?text=' + shareTitleEncode + '+' + shareLink + '+%23onlinesong+%23freemp3&source=webclient" target="_blank">Twitter</a>' +
                        '<a class="ok" href="http://www.odnoklassniki.ru/dk?st.cmd=addShare&st._surl=' + shareLink + '" target="_blank">Одноклассники</a>' +
                        '<a class="mm" href="http://connect.mail.ru/share?url=' + shareLink + '&title=' + shareTitleEncode + '&description=&imageurl=" target="_blank">Мой Мир</a>' +
                        '<a class="gp" href="https://plus.google.com/share?url=' + shareLink + '" target="_blank">Google Plus</a>' +
                        '</span><p style="color: var(--color-13); margin-bottom: 20px; font-size: 18px;">При нажатии на ссылку появится новое окно</p></span>';
                    $("body").append(appendCode);
                    $("body").find("span.import_ser-shareblock b").click(function () {
                        $(this).parent().remove();
                    });
                });

            $("a.lyrics")
                .on("click");

            $(this)
                .click(function (event) {
                    var excludes = $($(this).find(`*${artistBoxTitle} > a, b.artist, *${artistBoxLink}, span.lyrics, a.lyrics, *${artistBoxShareBtn}`), $(this));

                    if (!$(event.target).is(excludes)) {
                        if ($(this).hasClass("plays")) {
                            window.player.api('pause');
                            $(this)
                                .removeClass("plays")
                                .addClass("pausing");

                            $(`.${pleerContainer} ${importLongPlayerPauseBtn}`).hide();
                            $(`.${pleerContainer} ${importLongPlayerPlayBtn}`).show();
                        } else if ($(this).hasClass("pausing")) {
                            window.player.api('play');
                            $(this)
                                .removeClass("pausing")
                                .addClass("plays");
                            $(`.${pleerContainer} ${importLongPlayerPlayBtn}`).hide();
                            $(`.${pleerContainer} ${importLongPlayerPauseBtn}`).show();
                        } else {
                            let stringUrl = $(this).find(`*${artistBoxLink}`).attr("href");
                            																		   
                            url = isHls(stringUrl) ? stringUrl : `${stringUrl}${HLS}`;
                            window.playerUrl = url;
                            titles = $(this).find(`*${artistBoxTitle} a`);
                            title1 = $(titles[0]).text();
                            title2 = $(titles[1]).text();

                            $(`.${pleerContainer} ${importLongTitle}`)
                                .html(title1 + " - " + title2);

                            window.player.api("play", url.replace("/file/", "/"));

                            // $("div.results > div").removeClass("plays pausing");
                            $(`*${artistContainer} > *`).removeClass("plays pausing");
                            $(this).addClass("plays");
                        }
                    }
                })
                .hover(function () {

                });

            //$("a.lyrics").attr("title", "Текст песни");
            //$("a.link").attr("title", "Скачать");

            $(this).find(`*${artistBoxTitle} > a`)
                .click(function () {
                    $("input#string").val($(this).text().trim());
                    $('a#codeint').attr('href', $(this).attr('href'));
                });

            $(this).find("a.lyrics")
                .click(function () {
                    diving = $(this).parent();
                    diving
                        //.append($("<span/>", {"class": "lyrics", "html": $("<img/>", {"src": ".../images/loading.gif"})}));
                    linkvalue = $(this).attr("lid");
                    value = '/ajax/?lid=' + linkvalue;
                    $.ajax({
                        url: value,
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            alert('Ошибка');
                        },
                        success: function (data, textStatus, XMLHttpRequest) {
                            handlerLyrics(data, diving, 'http://' + document.domain);
                        }
                    });
                    return false;
                });
        });

    obj
        .find("a.nextpage")
        .unbind('click');

    obj
        .find("a.nextpage")
        .click(function (event) {
            $(this).addClass("load");
            linkvalue = $(this).attr("href");
            value = '/ajax' + linkvalue;

            $.ajax({
                url: value,
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    handlerNextPage(XMLHttpRequest.responseText, 'http://' + document.domain + linkvalue);
                },
                beforeSend: function () {
                    $('#preloader').html('<img src="/images/preloader.gif" />');
                },
                success: function (data, textStatus, XMLHttpRequest) {
                    $('#preloader').html('');
                    handlerNextPage(data, 'http://' + document.domain + linkvalue);
                }
            });
            return false;
        });
    //.hover(function(){$(this).click()});

    /*
    var screenHeight = $(window).height();
    $(window).scroll(function(){
        var scroll = $(this).scrollTop();
        var divHeight = $("#trackAjax").height();
        var totalHeight = screenHeight + scroll;
        var left = divHeight - totalHeight;
        var page = 0;
        if (left < 10)
            loadTracks(done, page);
        });
    */


    obj
        .find("a.prevpage")
        .click(function (event) {
            $(this).addClass("load");
            linkvalue = $(this).attr("href");
            value = '/ajax' + linkvalue + '&action=prev';
            $.ajax({
                url: value,
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    handlerPrevPage(XMLHttpRequest.responseText, 'http://' + document.domain + linkvalue);
                },
                success: function (data, textStatus, XMLHttpRequest) {
                    handlerPrevPage(data, 'http://' + document.domain + linkvalue);
                }
            });
            return false;
        });
    //.hover(function(){$(this).click()});
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function loadTracks(done, page) {
    if (isDebugger){
        console.log('loadTracks function');
    }
}

function change(obj) {
    url = obj.find(`*${artistBoxLink}`).attr("href");
    let titles = obj.find(`*${artistBoxTitle} a`),
        title1 = $(titles[0]).text(),
        title2 = $(titles[1]).text();

    let stringUrl = url,
        																	   
        changeUrlHls = isHls(stringUrl) ? stringUrl : `${stringUrl}${HLS}`;

    $(`.${pleerContainer} ${importLongTitle}`).html(`${title1} - ${title2}`);
    $(`.${pleerContainer} ${importLongDuration}`).text(obj.find(`*${artistDuration}`).text());

    if (isDebugger){
        console.log('function change working');
        console.log('isplay', isplay);
    }
    if (isplay === 1) {
        window.player.api('play', changeUrlHls.replace("/file/", "/"));
        isplay = 1;
    } else {
        window.player.api('file', changeUrlHls.replace("/file/", "/"));
    }
    window.playerUrl = isHls(stringUrl) ? stringUrl : `${stringUrl}${HLS}`;
    $(`.${pleerContainer} ${importLongTime}`).text(formatTime(0));
}

function PlayerjsEvents(event, id, info) {
    let playerBtnPlay = `.${pleerContainer} ${importLongPlayerPlayBtn}`,
        playerBtnPause = `.${pleerContainer} ${importLongPlayerPauseBtn}`,
        classId = $('.' + id),
        thisSongBox;
    $(playerBtnPlay).css({position: "relative"});
    $(playerBtnPause).css({position: "relative"});

    if (window.playerUrl){
        thisSongBox = $(`*${artistContainer} * > *[href='${window.playerUrl.replace(`${HLS}`, '')}']`);
    }

    switch (event) {
        case 'init':
            let object = $(`${artistContainer} > *`).eq(0);
            change(object);
            $(`.${pleerContainer} ${importLongPlayerPauseBtn}, ${btnUnmute}`).hide();
            break;
        case 'time':
            let allWidth = $(`.${pleerContainer} ${playerProgressBarContainer}`).width(),
                duration = window.player.api('duration'),
                secondWidth = allWidth / duration;
            $(`.${pleerContainer} ${playerProgressStatus}`).width(secondWidth * info);
            $(`.${pleerContainer} ${importLongTime}`).text(formatTime(info));
            break;
        case 'duration':
            if (isDebugger){
                console.log('case duration ... ');
            }
            $(`.${pleerContainer} ${importLongDuration}`).text(formatTime(info));
            break;
        case 'play':
            thisSongBox
                .closest(`${artistContainer} ${artistBox}`)
                .addClass("plays")
                .removeClass('pausing');

            classId
                .addClass("plays")
                .removeClass('pausing');

            isplay = 1;
            $(playerBtnPlay).hide();
            $(playerBtnPause).show();
            if (isDebugger){
                console.log('Play url : ', window.playerUrl);
            }
            break;
        case 'pause':
            thisSongBox
                .closest(`${artistContainer} ${artistBox}`)
                .addClass("pausing")
                .removeClass('plays');

            classId
                .addClass("pausing")
                .removeClass('plays');
            isplay = 0;
            $(playerBtnPause).hide();
            $(playerBtnPlay).show();
            break;
        case 'end':
            isplay = 0;
            $(`*${artistContainer}`).find("div.plays, div.pausing").next().click();
            break;
        case 'volume':
            $(`.${pleerContainer} ${volumeLine}`).css('width', (info * 100) + '%');
            break;
		case 'networkErrorHls':
		    if (isDebugger){
                console.log('------------ networkErrorHls ------------');
                console.log('Network Error Info: ', JSON.parse(info));
                console.log('------------ networkErrorHls END ------------');
            }
			break;
        case 'waiting':
            if (isDebugger){
                console.log('case waiting ... ');
            }
            break;
        default:
            if (isDebugger){
                console.log(event, id, info);
            }
            break;
    }
}

function formatTime(timestamp) {
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - (hours * 60);
    let seconds = Math.floor(timestamp % 60);
    return [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(":");
}

$(document).ready(
    function () {
        //Подгрузка треков инициализация
        var done = 1;
        var screenHeight = $(window).height();

        $(window).scroll(function () {
            var scroll = $(this).scrollTop();
            var divHeight = $("#trackAjax").height();
            var totalHeight = screenHeight + scroll;
            var left = divHeight - totalHeight;
            var page = 0;
            if (left < 10) {
                loadTracks(done, page);
            }
        });

        var vol = readCookie("volume");
        var scrolling = $(window).scrollTop();
        var hiding = 0;

        additems($(`* ${artistContainer}`));
        window.player = new Playerjs({
            id: pleerContainer,
            hls: (Array.from(document.querySelectorAll('a')).filter(function (a) {
                if (a.href.indexOf('getmp3') < 0) {
                    return false;
                }

                let href = new URL(location.protocol + a.href);
                if (!href.searchParams.has('s')) {
                    // Decrypting url
                    const parts = a.href.split('getmp3/');
                    const decrypted_part = atob(parts[1]);
                    href = new URL(location.protocol, parts[0] + 'getmp3/' + decrypted_part);
                }
                return href.searchParams.get('s') === 'vk';
            })).length > 0
        });		   
        $(`.${pleerContainer} ${importLongPlayerPlayBtn}`).click(function (event) {
            let pleerContainerClass = '.' + pleerContainer;
            let playerClass = $(event.target).parents(pleerContainerClass);

            if (playerClass.length > 0) {
                if ($(playerClass).hasClass("pausing")){
                    window.player.api('play');
                    $(playerClass).removeClass('pausing').addClass("plays");
                } else {
                    let stringUrl = url,
                        																	   
                        trackUrl = isHls(stringUrl) ? stringUrl : `${stringUrl}${HLS}`;
                    window.player.api("play", trackUrl.replace("/file/", "/"));
                    $(playerClass).addClass("plays").removeClass('pausing');
                }
            } else {
                if (isDebugger){
                    console.log('ERROR not found class: ' + '.'+pleerContainer);
                }
            }
        });

        $(`.${pleerContainer} ${importLongPlayerPauseBtn}`).click(function (event) {
            let pleerContainerClass = '.' + pleerContainer;
            let playerClass = $(event.target).parents(pleerContainerClass);

            if (playerClass.length > 0) {
                $(playerClass).addClass("pausing").removeClass('plays');

                window.player.api('pause');
            } else {
                if (isDebugger){
                    console.log('ERROR not found class: ' + '.'+pleerContainer );
                }
            }
        });

        $(`.${pleerContainer} ${btnMute}`).click(function (event) {
            window.player.api('mute');
            $(this).hide();
            $(`.${pleerContainer} ${btnUnmute}`).show();
        });

        $(`.${pleerContainer} ${btnUnmute}`).click(function (event) {
            window.player.api('unmute');
            $(this).hide();
            $(`.${pleerContainer} ${btnMute}`).show();
        });

        $(`.${pleerContainer} ${playerProgressBarContainer}`).click(function (e) {
            let allWidth = $(`.${pleerContainer} ${playerProgressBarContainer}`).width();
            let position = e.offsetX;
            let duration = window.player.api('duration');
            window.player.api('seek', position / allWidth * duration);
        });

        $(`.${pleerContainer} ${volume}`).click(function (e) {
            let allWidth = $(this).width();
            let position = e.offsetX;
            window.player.api('volume', position / allWidth);
            createCookie("volume", position / allWidth, 365);
        });

        $(`.${pleerContainer} ${backwardSong}`).click(function () {
            let thisSongBox = null;
            if (window.playerUrl){
                thisSongBox = $(`*${artistContainer} * > *[href='${window.playerUrl.replace(`${HLS}`, '')}']`);
            }

            if (thisSongBox && thisSongBox.parent().prev("div").is("div")) {
                thisSongBox.parent().attr("class", `${artistBox}`.slice(1));
                thisSongBox.parent().prev("div").addClass("pausing");
                change(thisSongBox.parent().prev("div"));
            }
        });
        $(`.${pleerContainer} ${forwardSong}`).click(function () {
            let thisSongBox = null;
            if (window.playerUrl){
                thisSongBox = $(`*${artistContainer} * > *[href='${window.playerUrl.replace(`${HLS}`, '')}']`);
            }

            if (thisSongBox && thisSongBox.parent().next("div").is("div")) {
                thisSongBox.parent().attr("class", `${artistBox}`.slice(1));

                thisSongBox.parent().next("div").addClass("pausing");
                change(thisSongBox.parent().next("div"));
            }
        });
    }
);

var t = {};

t['%D0%B0'] = '%E0';
t['%D0%B1'] = '%E1';
t['%D0%B2'] = '%E2';
t['%D0%B3'] = '%E3';
t['%D0%B4'] = '%E4';
t['%D0%B5'] = '%E5';
t['%D1%91'] = '%B8';
t['%D0%B6'] = '%E6';
t['%D0%B7'] = '%E7';
t['%D0%B8'] = '%E8';
t['%D0%B9'] = '%E9';
t['%D0%BA'] = '%EA';
t['%D0%BB'] = '%EB';
t['%D0%BC'] = '%EC';
t['%D0%BD'] = '%ED';
t['%D0%BE'] = '%EE';
t['%D0%BF'] = '%EF';
t['%D1%80'] = '%F0';
t['%D1%81'] = '%F1';
t['%D1%82'] = '%F2';
t['%D1%83'] = '%F3';
t['%D1%84'] = '%F4';
t['%D1%85'] = '%F5';
t['%D1%86'] = '%F6';
t['%D1%87'] = '%F7';
t['%D1%88'] = '%F8';
t['%D1%89'] = '%F9';
t['%D1%8C'] = '%FC';
t['%D1%8B'] = '%FB';
t['%D1%8A'] = '%FA';
t['%D1%8D'] = '%FD';
t['%D1%8E'] = '%FE';
t['%D1%8F'] = '%FF';
t['%D0%90'] = '%C0';
t['%D0%91'] = '%C1';
t['%D0%92'] = '%C2';
t['%D0%93'] = '%C3';
t['%D0%94'] = '%C4';
t['%D0%95'] = '%C5';
t['%D0%81'] = '%A8';
t['%D0%96'] = '%C6';
t['%D0%97'] = '%C7';
t['%D0%98'] = '%C8';
t['%D0%99'] = '%C9';
t['%D0%9A'] = '%CA';
t['%D0%9B'] = '%CB';
t['%D0%9C'] = '%CC';
t['%D0%9D'] = '%CD';
t['%D0%9E'] = '%CE';
t['%D0%9F'] = '%CF';
t['%D0%A0'] = '%D0';
t['%D0%A1'] = '%D1';
t['%D0%A2'] = '%D2';
t['%D0%A3'] = '%D3';
t['%D0%A4'] = '%D4';
t['%D0%A5'] = '%D5';
t['%D0%A6'] = '%D6';
t['%D0%A7'] = '%D7';
t['%D0%A8'] = '%D8';
t['%D0%A9'] = '%D9';
t['%D0%AC'] = '%DC';
t['%D0%AB'] = '%DB';
t['%D0%AA'] = '%DA';
t['%D0%AD'] = '%DD';
t['%D0%AE'] = '%DE';
t['%D0%AF'] = '%DF';

t['%D1%94'] = '%BA';
t['%D0%84'] = '%AA';
t['%D1%96'] = '%B3';
t['%D0%86'] = '%B2';
t['%D1%97'] = '%BF';
t['%D0%87'] = '%AF';
t['%D2%91'] = '%B4';
t['%D2%90'] = '%A5';
t['%D1%91'] = '%B8';
t['%D0%81'] = '%A8';

// function convert_to_cp1251(str) {
//     var ret = '';
//     var l = str.length;
//     var i = 0;
//     while (i < l) {
//         var f = 0;
//         for (keyVar in t) {
//             if (str.substring(i, i + 6) == keyVar) {
//                 ret += t[keyVar];
//                 i += 6;
//                 f = 1;
//             }
//         }
//         if (!f) {
//             ret += str.substring(i, i + 1);
//             i++;
//         }
//     }
//     ret = ret.replace(RegExp('%20', 'g'), '+');
//     ret = ret.replace(RegExp('%E2%80%93', 'g'), '-');
//     ret = ret.replace(RegExp('%E2%80%94', 'g'), '-');
//     return ret;
// }

//$("<img />").attr("src", '.../images/loading.gif');


(function (b) {
    function I(a, d, e) {
        var c = this;
        return this.on("click.pjax", a, function (a) {
            var f = b.extend({}, l(d, e));
            f.container || (f.container = b(this).attr("data-pjax") || c);
            z(a, f)
        })
    }

    function z(a, d, e) {
        e = l(d, e);
        d = a.currentTarget;
        if ("A" !== d.tagName.toUpperCase()) throw"$.fn.pjax or $.pjax.click requires an anchor element";
        if (!(1 < a.which || a.metaKey || a.ctrlKey || a.shiftKey || a.altKey || location.protocol !== d.protocol || location.hostname !== d.hostname || -1 < d.href.indexOf("#") && d.href.replace(/#.*/, "") == location.href.replace(/#.*/,
            "") || a.isDefaultPrevented())) {
            var c = {url: d.href, container: b(d).attr("data-pjax"), target: d};
            e = b.extend({}, c, e);
            c = b.Event("pjax:click");
            b(d).trigger(c, [e]);
            c.isDefaultPrevented() || (f(e), a.preventDefault(), b(d).trigger("pjax:clicked", [e]))
        }
    }

    function J(a, d, e) {
        e = l(d, e);
        d = a.currentTarget;
        var c = b(d);
        if ("FORM" !== d.tagName.toUpperCase()) throw"$.pjax.submit requires a form element";
        c = {
            type: (c.attr("method") || "GET").toUpperCase(),
            url: c.attr("action"),
            container: c.attr("data-pjax"),
            target: d
        };
        if ("GET" !== c.type &&
            void 0 !== window.FormData) c.data = new FormData(d), c.processData = !1, c.contentType = !1; else {
            if (b(d).find(":file").length) return;
            c.data = b(d).serializeArray()
        }
        f(b.extend({}, c, e));
        a.preventDefault()
    }

    function f(a) {
        function d(a, d, c) {
            c || (c = {});
            c.relatedTarget = e;
            a = b.Event(a, c);
            h.trigger(a, d);
            return !a.isDefaultPrevented()
        }

        a = b.extend(!0, {}, b.ajaxSettings, f.defaults, a);
        b.isFunction(a.url) && (a.url = a.url());
        var e = a.target, c = t(a.url).hash, h = a.context = A(a.container);
        a.data || (a.data = {});
        b.isArray(a.data) ? a.data.push({
            name: "_pjax",
            value: h.selector
        }) : a.data._pjax = h.selector;
        var k;
        a.beforeSend = function (b, e) {
            "GET" !== e.type && (e.timeout = 0);
            b.setRequestHeader("X-PJAX", "true");
            b.setRequestHeader("X-PJAX-Container", h.selector);
            if (!d("pjax:beforeSend", [b, e])) return !1;
            0 < e.timeout && (k = setTimeout(function () {
                d("pjax:timeout", [b, a]) && b.abort("timeout")
            }, e.timeout), e.timeout = 0);
            var f = t(e.url);
            c && (f.hash = c);
            a.requestUrl = B(f)
        };
        a.complete = function (b, c) {
            k && clearTimeout(k);
            d("pjax:complete", [b, c, a]);
            d("pjax:end", [b, a])
        };
        a.error = function (b, c, e) {
            var f =
                C("", b, a);
            b = d("pjax:error", [b, c, e, a]);
            "GET" == a.type && "abort" !== c && b && u(f.url)
        };
        a.success = function (e, m, k) {
            var n = f.state,
                p = "function" === typeof b.pjax.defaults.version ? b.pjax.defaults.version() : b.pjax.defaults.version,
                l = k.getResponseHeader("X-PJAX-Version"), g = C(e, k, a), q = t(g.url);
            c && (q.hash = c, g.url = q.href);
            if (p && l && p !== l) u(g.url); else if (g.contents) {
                f.state = {
                    id: a.id || (new Date).getTime(),
                    url: g.url,
                    title: g.title,
                    container: h.selector,
                    fragment: a.fragment,
                    timeout: a.timeout
                };
                (a.push || a.replace) && window.history.replaceState(f.state,
                    g.title, g.url);
                if (b.contains(a.container, document.activeElement)) try {
                    document.activeElement.blur()
                } catch (r) {
                }
                g.title && (document.title = g.title);
                d("pjax:beforeReplace", [g.contents, a], {state: f.state, previousState: n});
                h.html(g.contents);
                (n = h.find("input[autofocus], textarea[autofocus]").last()[0]) && document.activeElement !== n && n.focus();
                K(g.scripts);
                g = a.scrollTo;
                c && (n = decodeURIComponent(c.slice(1)), n = document.getElementById(n) || document.getElementsByName(n)[0]) && (g = b(n).offset().top);
                "number" == typeof g &&
                b(window).scrollTop(g);
                d("pjax:success", [e, m, k, a])
            } else u(g.url)
        };
        f.state || (f.state = {
            id: (new Date).getTime(),
            url: window.location.href,
            title: document.title,
            container: h.selector,
            fragment: a.fragment,
            timeout: a.timeout
        }, window.history.replaceState(f.state, document.title));
        D(f.xhr);
        f.options = a;
        var m = f.xhr = b.ajax(a);
        0 < m.readyState && (a.push && !a.replace && (L(f.state.id, E(h)), window.history.pushState(null, "", a.requestUrl)), d("pjax:start", [m, a]), d("pjax:send", [m, a]));
        return f.xhr
    }

    function M(a, d) {
        return f(b.extend({
            url: window.location.href,
            push: !1, replace: !0, scrollTo: !1
        }, l(a, d)))
    }

    function u(a) {
        window.history.replaceState(null, "", f.state.url);
        window.location.replace(a)
    }

    function F(a) {
        q || D(f.xhr);
        var d = f.state, e = a.state, c;
        if (e && e.container) {
            if (q && N == e.url) return;
            if (d) {
                if (d.id === e.id) return;
                c = d.id < e.id ? "forward" : "back"
            }
            var h = p[e.id] || [];
            a = b(h[0] || e.container);
            h = h[1];
            if (a.length) {
                if (d) {
                    var k = c, m = d.id, l = E(a);
                    p[m] = l;
                    "forward" === k ? (k = r, l = v) : (k = v, l = r);
                    k.push(m);
                    (m = l.pop()) && delete p[m];
                    w(k, f.defaults.maxCacheLength)
                }
                c = b.Event("pjax:popstate",
                    {state: e, direction: c});
                a.trigger(c);
                c = {
                    id: e.id,
                    url: e.url,
                    container: a,
                    push: !1,
                    fragment: e.fragment,
                    timeout: e.timeout,
                    scrollTo: !1
                };
                h ? (a.trigger("pjax:start", [null, c]), f.state = e, e.title && (document.title = e.title), d = b.Event("pjax:beforeReplace", {
                    state: e,
                    previousState: d
                }), a.trigger(d, [h, c]), a.html(h), a.trigger("pjax:end", [null, c])) : f(c);
                a[0].offsetHeight
            } else u(location.href)
        }
        q = !1
    }

    function O(a) {
        var d = b.isFunction(a.url) ? a.url() : a.url, e = a.type ? a.type.toUpperCase() : "GET", c = b("<form>", {
            method: "GET" === e ? "GET" :
                "POST", action: d, style: "display:none"
        });
        "GET" !== e && "POST" !== e && c.append(b("<input>", {
            type: "hidden",
            name: "_method",
            value: e.toLowerCase()
        }));
        a = a.data;
        if ("string" === typeof a) b.each(a.split("&"), function (a, d) {
            var e = d.split("=");
            c.append(b("<input>", {type: "hidden", name: e[0], value: e[1]}))
        }); else if (b.isArray(a)) b.each(a, function (a, d) {
            c.append(b("<input>", {type: "hidden", name: d.name, value: d.value}))
        }); else if ("object" === typeof a) for (var f in a) c.append(b("<input>", {
            type: "hidden",
            name: f,
            value: a[f]
        }));
        b(document.body).append(c);
        c.submit()
    }

    function D(a) {
        a && 4 > a.readyState && (a.onreadystatechange = b.noop, a.abort())
    }

    function E(a) {
        var b = a.clone();
        b.find("script").each(function () {
            this.src || jQuery._data(this, "globalEval", !1)
        });
        return [a.selector, b.contents()]
    }

    function B(a) {
        a.search = a.search.replace(/([?&])(_pjax|_)=[^&]*/g, "");
        return a.href.replace(/\?($|#)/, "$1")
    }

    function t(a) {
        var b = document.createElement("a");
        b.href = a;
        return b
    }

    function l(a, d) {
        a && d ? d.container = a : d = b.isPlainObject(a) ? a : {container: a};
        d.container && (d.container = A(d.container));
        return d
    }

    function A(a) {
        a = b(a);
        if (a.length) {
            if ("" !== a.selector && a.context === document) return a;
            if (a.attr("id")) return b("#" + a.attr("id"));
            throw"cant get selector for pjax container!";
        }
        throw"no pjax container for " + a.selector;
    }

    function x(a, b) {
        return a.filter(b).add(a.find(b))
    }

    function C(a, d, e) {
        var c = {}, f = /<html/i.test(a);
        d = d.getResponseHeader("X-PJAX-URL");
        c.url = d ? B(t(d)) : e.requestUrl;
        f ? (d = b(b.parseHTML(a.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0], document, !0)), a = b(b.parseHTML(a.match(/<body[^>]*>([\s\S.]*)<\/body>/i)[0],
            document, !0))) : d = a = b(b.parseHTML(a, document, !0));
        if (0 === a.length) return c;
        c.title = x(d, "title").last().text();
        e.fragment ? (f = "body" === e.fragment ? a : x(a, e.fragment).first(), f.length && (c.contents = "body" === e.fragment ? f : f.contents(), c.title || (c.title = f.attr("title") || f.data("title")))) : f || (c.contents = a);
        c.contents && (c.contents = c.contents.not(function () {
            return b(this).is("title")
        }), c.contents.find("title").remove(), c.scripts = x(c.contents, "script[src]").remove(), c.contents = c.contents.not(c.scripts));
        c.title &&
        (c.title = b.trim(c.title));
        return c
    }

    function K(a) {
        if (a) {
            var d = b("script[src]");
            a.each(function () {
                var a = this.src;
                if (!d.filter(function () {
                    return this.src === a
                }).length) {
                    var c = document.createElement("script"), f = b(this).attr("type");
                    f && (c.type = f);
                    c.src = b(this).attr("src");
                    document.head.appendChild(c)
                }
            })
        }
    }

    function L(a, b) {
        p[a] = b;
        r.push(a);
        w(v, 0);
        w(r, f.defaults.maxCacheLength)
    }

    function w(a, b) {
        for (; a.length > b;) delete p[a.shift()]
    }

    function P() {
        return b("meta").filter(function () {
            var a = b(this).attr("http-equiv");
            return a && "X-PJAX-VERSION" === a.toUpperCase()
        }).attr("content")
    }

    function G() {
        b.fn.pjax = I;
        b.pjax = f;
        b.pjax.enable = b.noop;
        b.pjax.disable = H;
        b.pjax.click = z;
        b.pjax.submit = J;
        b.pjax.reload = M;
        b.pjax.defaults = {
            timeout: 650,
            push: !0,
            replace: !1,
            type: "GET",
            dataType: "html",
            scrollTo: 0,
            maxCacheLength: 20,
            version: P
        };
        b(window).on("popstate.pjax", F)
    }

    function H() {
        b.fn.pjax = function () {
            return this
        };
        b.pjax = O;
        b.pjax.enable = G;
        b.pjax.disable = b.noop;
        b.pjax.click = b.noop;
        b.pjax.submit = b.noop;
        b.pjax.reload = function () {
            window.location.reload()
        };
        b(window).off("popstate.pjax", F)
    }

    var q = !0, N = window.location.href, y = window.history.state;
    y && y.container && (f.state = y);
    "state" in window.history && (q = !1);
    var p = {}, v = [], r = [];
    0 > b.inArray("state", b.event.props) && b.event.props.push("state");
    b.support.pjax = window.history && window.history.pushState && window.history.replaceState && !navigator.userAgent.match(/((iPod|iPhone|iPad).+\bOS\s+[1-4]\D|WebApps\/.+CFNetwork)/);
    b.support.pjax ? G() : H()
})(jQuery);


